const modalItemSpecialists = document.querySelector('[data-modal-confirm-specialists=""]');

if (modalItemSpecialists) {
  const modalItemSpecialistsConfirm = modalItemSpecialists.querySelector('[data-modal-confirm-specialists="confirm"]');
  const modalItemSpecialistsClose = [...modalItemSpecialists.querySelectorAll('[data-modal-confirm-specialists="close"]')];

  modalItemSpecialistsConfirm.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();

    localStorage.setItem('is-user-spec', 'true');

    modalItemSpecialists.classList.remove('is-active');

    if (window.location.pathname.indexOf('/pro/') < 0) {
      window.location = document.querySelector('[data-menu-list="specialists"]').getAttribute('href');
    }
  });

  modalItemSpecialistsClose.forEach((btnClose) => {
    btnClose.addEventListener('click', (event) => {
      event.preventDefault();

      modalItemSpecialists.classList.remove('is-active');

      if (window.location.pathname !== '/') {
        window.location = '/';
      }
    });
  });
}
